const TECHNOLOGIES = {
  python: { src: "./images/python.png", tooltip: "Python" },
  django: { src: "./images/django.png", tooltip: "Django" },
  postgres: { src: "./images/postgresql.png", tooltip: "PostgreSQL" },
  aws: { src: "./images/aws.png", tooltip: "AWS" },
  heroku: { src: "./images/heroku.png", tooltip: "Heroku" },
  flask: { src: "./images/flask.png", tooltip: "Flask" },
  mongo: { src: "./images/mongodb.png", tooltip: "MongoDB" },
  javaScript: { src: "./images/javascript.png", tooltip: "JavaScript" },
  jQuery: { src: "./images/jquery.png", tooltip: "jQuery" },
  html: { src: "./images/html.png", tooltip: "HTML5" },
  css: { src: "./images/css.png", tooltip: "CSS3" },
  bootstrap: { src: "./images/bootstrap.png", tooltip: "Bootstrap" },
};

export const ProjectItems = [
  {
    id: 1,
    img: "./images/project1.png",
    title: "Pickles",
    about: "An e-commerce website built with the Django Framework.",
    liveURL: "https://pickles.onrender.com/",
    repoURL: "https://github.com/Johnny-Morgan/pickles",
    technologies: [
      TECHNOLOGIES.python,
      TECHNOLOGIES.django,
      TECHNOLOGIES.postgres,
      TECHNOLOGIES.aws,
      TECHNOLOGIES.heroku,
      TECHNOLOGIES.javaScript,
      TECHNOLOGIES.html,
      TECHNOLOGIES.css,
      TECHNOLOGIES.bootstrap,
    ],
    initialAnimationState: { opacity: 0, x: -300 },
    finalAnimationState: { opacity: 1, x: 0 },
  },
  {
    id: 2,
    img: "./images/project2.png",
    title: "Hike Logger",
    about: "The perfect place to log and share your hikes.",
    liveURL: "https://hike-logger.onrender.com/",
    repoURL: "https://github.com/Johnny-Morgan/hike-logger",
    technologies: [
      TECHNOLOGIES.python,
      TECHNOLOGIES.flask,
      TECHNOLOGIES.mongo,
      TECHNOLOGIES.heroku,
      TECHNOLOGIES.html,
      TECHNOLOGIES.css,
      TECHNOLOGIES.bootstrap,
    ],
    initialAnimationState: { opacity: 0 },
    finalAnimationState: { opacity: 1 },
  },
  {
    id: 3,
    img: "./images/project3.png",
    title: "Memory Blocks",
    about: "A fun and challenging memory game.",
    liveURL: "https://johnny-morgan.github.io/memory-blocks/",
    repoURL: "https://github.com/Johnny-Morgan/memory-blocks",
    technologies: [
      TECHNOLOGIES.javaScript,
      TECHNOLOGIES.jQuery,
      TECHNOLOGIES.html,
      TECHNOLOGIES.css,
      TECHNOLOGIES.bootstrap,
    ],
    initialAnimationState: { opacity: 0, x: 300 },
    finalAnimationState: { opacity: 1, x: 0 },
  },
];
